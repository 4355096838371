// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$grey: #E9E9E9;
$grey-off: #C4C4C4;
$grey-on: #4F4F4F;
$grey-dark: #6A6A6A;
$grey-light: #EFEFEF;

$green: #009900;

$red: #E30713;
$div_error: #FFD9D9;

$color-size: 60px;


body {
  background: $grey;
  padding-bottom: 30px;
}

*:focus{
  outline: none;
}

p {
  &.center {
    text-align: center;
  }
}

div#designer {
  width: 1024px;
}

div.box {
  background: #FFF;
  margin-bottom: 5px;
}

div#menu {
  background: #000;
  padding: 10px 10px 0;
  color: #FFF;
  font-size: 2rem;
}

div#trade-user-header {
  background: #666666;
  padding: 10px 0;
  margin-bottom: 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
  color: #CCC;
}

div#main-container {
  height: 200px;
}

div.need-input {
  background-color: $div_error;
  transition: 0.5s ease all;
}

input.error {
  border: 1px solid $red;
  border-right: 20px solid $red;
}

label.error {
  color: $red;
  font-weight: bold;
}

p {
  > small {
    font-size: 75%;
    padding-left: 20px;
  }
}

a.btn-edit {
  vertical-align: bottom;
  margin-bottom: 20px;
}

div#nav-list a.list-group-item {

  &:hover {
    color: $red;
  }
}

/*
 * configuration options - top left box
 */
div#step-picker {

  position: relative;
  background: #ffffff;
  padding-left: 0px;
  padding-right: 0px;

  // bottom arrow
  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 12px;
    margin-left: -12px;
  }

  > h1 {
    padding-bottom: 16px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 2px solid $red;
    margin-bottom: 0;
  }

  ul#build-options {
    list-style-type: none;
    padding: 10px;
    margin-bottom: 0;

    > li {
      padding: 4px 10px;
      margin-bottom: 2px;
      background: #FFF;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      font-size: 1.1rem;
      text-transform: uppercase;
      cursor: pointer;

      &::before {
        content: url(/images/icons/tick-holder.png);
      }

      &.active {
        background: $red;
        color: #FFF;

        &::before {
          padding-right: 3px;
          content: url(/images/icons/tick.png);
        }
      }

      &.closed {
        color: $grey-off;
      }
    }
  }

}



/*
 * door image and colour picker container
 */
div#visual-container {
  height: 360px;

  > div {
    text-align: center;

    p {
      font-size: 1.2rem;
      color: #777;
    }

  }

  // door preview container
  > div#door-preview {
    position: relative;
    padding-bottom: 60px;

    > i#loading-door {
      position: absolute;
      top: 129px;
      left: 281px;
    }

    > p {
      font-size: 1.2rem;
      color: #777;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;

      &.under-door {
        padding-top: 20px;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  // vent preview
  > div#vent-preview {
    position: relative;

    > div#vent-picker {
      margin-top: 20px;
      padding-bottom: 40px;

      // start left margin for the vent options based on number of panels selected
      &[data-panels="2"] { margin-left: 238px; }
      &[data-panels="3"] { margin-left: 191px; }
      &[data-panels="4"] { margin-left: 144px; }
      &[data-panels="5"] { margin-left: 97px; }
      &[data-panels="6"] { margin-left: 50px; }
      &[data-panels="7"] { margin-left: 4px; }

      > div {
        float: left;
        margin: 0 10px;
        width: 72px;
        border: 1px solid #CCC;
        cursor: pointer;
        
        > i {
          display: none;
        }

        &.active {
          border-color: $red;
          
          > i {
            display: inline-block;
          }
        }
      }
    }
  }

  // colour picker
  div#colour-picker {
    display: none;

    // tooltips for ral colour picker
    li:not(.active).tooltip-toggle {
      cursor: pointer;
      position: relative;

      &::before {
        background-color: #000;
        border: 2px solid #FFF;
        border-radius: 5px;
        color: #fff;
        content: attr(data-tooltip);
        left: -12px;
        padding: 1rem;
        position: absolute;
        text-transform: none;
        top: -20px;
        transition: all 0.5s ease;
        width: 80px;
        font-size: 1.1rem;
        z-index: 999;
      }

      &::before,
      &::after {
        opacity: 0;
        pointer-events: none;
      }

      &:hover::before,
      &:hover::after {
        opacity: 1;
        transition: all 0.75s ease;
      }
    }
  }
}


/*
  hardware colours
*/
div#hardware-colours {
  //helper ? icon
  p {
    text-align: right;
    margin: 0;
    font-weight: bold;
    padding: 0 15px;
    float: right;
  }
}


/*
 * summary
 */
div#summary {

  padding-top: 10px;

  p {
    &.total {
      font-weight: bold;
      font-size: 1.6rem;
    }

    &.discount {
      font-size: 1.2rem;
      line-height: 20px;
      color: $green;
    }

    &.small {
      font-size: 1rem;
      line-height: 0px;
      color: $grey-off;
    }

    &.option {
      font-size: 1.1rem;
      cursor: pointer;
    }
    
    > a {
      color: $grey-on;
      text-decoration: none;
    }

  }

}


/*
 * data tables
 */
table.datatable {
  width: 100%;
}


/*
 * message box
 */
div#message-box {
  background: #FFF;
  height: 200px;

  h1 {
    padding: 20px 40px;
    font-size: 20px;
  }

  p {
    padding: 0px 40px;
  }

  a {
    padding: 0px 40px;
  }
}

span.text-icon {
  display: table-cell;
  width: 88px;
  height: 88px;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  color: #333;
  background-color: #ffffff;
  padding: 2px;
  border: 2px solid #E9E9E9;

  &.small-font {
    font-size: 14px;
  }
}

div.active span.text-icon {
  border: 2px solid #E30713;
}

/*
 * configuration options - second from bottom box
 */
div#configuration {

  /*
   * general
   */

  > div > div {
    min-height: 260px;
  }

  div.inner {
    //padding: 30px 40px;
    padding: 30px 0px 10px;
  }

  div.form-group.sizing-fields {
    margin-bottom: 10px;
  }
  
  .toggle {
    cursor: pointer;
  }

  label {
    font-weight: normal;
  }



  /*
   * size options
   */

  > div#options-size > div {
    &:nth-child(1) {
      width: 49%;
      margin-right: 1%;
    }

    &:nth-child(2) {
      width: 49%;
      margin-left: 1%;
    }
  }



  /*
   * config options
   */

  div.configs {
    text-align: center;

    img {
      padding: 6px 8px;
      border: 2px solid #FFF;
    }

    div.active img {
      border: 2px solid #E30713;
    }

    div.door {
      float: left;
      position: relative;
      width: 33%;

      > div.player {
        display: none;
      }

      > div.panel-count {
        display: none;
      }

      &.active {
        > div.panel-count {
          display: block;
          position: absolute;
          background: #E30713;
          width: 60px;
          height: 15px;
          left: 42px;
          color: #FFF;
          font-size: 1.2rem;
          border-radius: 0 0 4px;
        }

        > div.player {
          display: block;
          position: absolute;
          top: 0px;
          right: 0px;
          padding-top: 10px;
          background: $red;
          width: 60px;
          height: 100%;
          color: #FFF;
          font-size: 1rem;
          line-height: 1.2rem;

          > img {
            padding: 6px 0 4px;
          }
        }
      }
    }
  }



  /*
   * colours
   */

  div.colours {

    &#external-colours {
      div.colour {
        > div.ral-colour-holder::before {
          position: absolute;
          bottom: 2px;
          left: 7px;
          padding: 2px 6px;
          width: 70px;
          background: #000;
          -webkit-border-radius: 6px 6px 0 0;
          -moz-border-radius: 6px 6px 0 0;
          border-radius: 6px 6px 0 0;
          content: "CLICK TO CHANGE";
          font-size: 0.9rem;
          color: #FFF;
          letter-spacing: 1px;
          opacity: 0.6;
        }
        > div.texture-colour-holder::before {
          position: absolute;
          bottom: 2px;
          left: 7px;
          padding: 2px 6px;
          width: 70px;
          background: #000;
          -webkit-border-radius: 6px 6px 0 0;
          -moz-border-radius: 6px 6px 0 0;
          border-radius: 6px 6px 0 0;
          content: "CLICK TO CHANGE";
          font-size: 0.9rem;
          color: #FFF;
          letter-spacing: 1px;
          opacity: 0.6;
        }
      }
    }

    &#internal-colours {
      div.colour {
        > div.ral-colour-holder-int::before {
          //position: absolute;
          //bottom: 2px;
          //left: 7px;
          //padding: 2px 6px;
          //width: 70px;
          //background: #000;
          //-webkit-border-radius: 6px 6px 0 0;
          //-moz-border-radius: 6px 6px 0 0;
          //border-radius: 6px 6px 0 0;
          //content: "CLICK TO CHANGE";
          //font-size: 0.9rem;
          //color: #FFF;
          //letter-spacing: 1px;
          //opacity: 0.6;
        }
      }
    }

    &#glazing-options, &#blind-options {
      div.colour {
        > div.blind-holder::before {
          position: absolute;
          bottom: 2px;
          left: 7px;
          padding: 2px 6px;
          width: 70px;
          background: #000;
          -webkit-border-radius: 6px 6px 0 0;
          -moz-border-radius: 6px 6px 0 0;
          border-radius: 6px 6px 0 0;
          content: "CLICK TO CHANGE";
          font-size: 0.9rem;
          color: #FFF;
          letter-spacing: 1px;
          opacity: 0.6;
        }
      }
    }

    &.static > div.colour {
      float: left;
      width: 120px;

    }

    &.small-gap > div.colour {
      width: 100px;
    }

    div.colour {
      position: relative;
      padding: 4px;
      margin: 0;
      font-size: 1.1rem;
      text-align: center;
      cursor: pointer;

      &.active > img {
        border: 2px solid $red;
      }

      > img {
        padding: 2px;
        border: 2px solid $grey;
      }

      &.active > div.ral-colour-holder, &.active > div.ral-colour-holder-int, &.active > div.texture-colour-holder, &.active > div.blind-colour-holder {
        border-color: $red;
      }

      > div.ral-colour-holder, > div.ral-colour-holder-int, > div.texture-colour-holder, > div.blind-colour-holder {
        position: relative;
        padding: 2px;
        margin: 0 auto;
        width: 88px;
        height: 88px;
        border: 2px solid $grey;

        > div.colour-box {
          height: 100%;
          width: 100%;
        }
      }
      > div.blind-colour-holder {
        width: 72px;
        height: 72px;
      }
    }

  }

  div.add-on-picker {
    width: 60px;
    float: right;

    > a.btn {
      width: 50px;
      margin-bottom: 2px;
      margin-left: 10px;
      border-width: 2px;

      &.active {
        border: 2px solid $red;
      }
    }
  }

}


/*
 * footer row
 */
div.footer-buttons {
  width: 1024px;
  margin: 0 auto;
  padding-top: 20px;

  > div {
    padding: 0px;
    cursor: pointer;

    &#back {
      > div > i {
        left: 10px;
      }
    }

    &#next {
      > div > i {
        right: 10px;
      }
    }

    &#restart {
      padding: 0 20px;

      > div {
        background: $grey-off;
      }
    }

    > div {
      position: relative;
      background: $red;
      width: 100%;
      padding: 10px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      text-align: center;
      font-size: 1.8rem;
      color: #FFF;

      &.wait {
        background-color: $grey-off;
        cursor: not-allowed;
        
        > a {
          cursor: inherit;
        }
      }

      &#summary-button {
        background-color: $green;
      }

      > i.fa {
        position: absolute;
        top: 14px;
      }

      > a {
        color: #FFF;
        
        &:hover {
          text-decoration: none;
        }
      }
    }

  }
}


/*
 * lists
 */

ul#slide-direction {

  > li {
    margin-right: 40px;
  }

}

ul.horizontal {

  list-style-type: none;
  padding: 0px;

  &.outlined > li > img {
    border: 2px solid $grey;
  }

  &.gap > li > img {
    padding: 10px;
  }

  > li {
    display: inline-block;
    text-align: center;
    font-size: 1.2rem;
    color: #777;

    > img {
      padding: 2px;
      margin-bottom: 10px;
      border: 2px solid #FFF;
      width: inherit;
      height: inherit;
    }

    &.active > img {
      border: 2px solid #E30713;
    }

  }

  &.ral {

    > li {
      position: relative;
      border: 2px solid #FFF;
      padding: 1px;
      margin: 2px 2px 15px;
      height: $color-size;
      width: $color-size;
      cursor: pointer;

      > span {
        width: inherit;
        position: absolute;
        left: 0;
        bottom: -22px;
        height: 20px;
      }

      &.active {
        border-color: $red;

        &::before {
          position: absolute;
          content: url(/images/icons/ral-tick.png);
        }
      }
    }
  }


  &.ral-textured {

    > li {
      position: relative;
      //border: 2px solid #FFF;
      //padding: 1px;
      margin: 2px 2px 32px;
      height: 90px;
      width: 94px;
      cursor: pointer;

      > span {
        width: inherit;
        position: absolute;
        left: 0;
        bottom: -37px;
        height: 35px;
      }

      &.active {
        border-color: $red;

        &::before {
          position: absolute;
          content: url(/images/icons/ral-tick.png);
        }
      }
    }

  }

  &.foil {

    > li {
      position: relative;
      //border: 2px solid #FFF;
      padding: 1px;
      height: 90px;
      width: 94px;
      margin: 2px 2px 32px;
      cursor: pointer;

      > span {
        width: inherit;
        position: absolute;
        left: 0;
        bottom: -37px;
        height: 35px;
      }

      &.active {
        border-color: $red;

        &::before {
          position: absolute;
          content: url(/images/icons/ral-tick.png);
        }
      }
    }

  }
}

#ral-colours.horizontal {

  &.ral {

    > li {
      margin: 2px 2px 55px;

      > span {
        bottom: -42px;
        height: 40px;
      }
    }
  }
}

.sub-text {
  font-size: 12px;
}

ul.configs > li.active > img {
  border: 2px solid #E30713;
}


/*
 * slick
 */

.slick-slider {
  margin-bottom: 20px;
}

.slick-slide img {
  display: inline;
}



.slide-button {
  position: absolute;
  top: 78px;
  height: 66px;
  width: 30px;
  background-color: $grey-off;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease all;
  z-index: 999;

  &:hover {
    background-color: $grey-on;
    transition: 0.3s ease all;
  }

  &.prev {
    left: 12px;
    background-image: url('/images/icons/scroll-left.png');
  }

  &.next {
    right: 12px;
    background-image: url('/images/icons/scroll-right.png');
  }

}

.paginator{
  position: relative;
  float: right;
  margin-bottom: 20px;

  li{
    margin-top: 20px;
    position: relative;
    float: left;

    margin-right: 20px;
  }
}

div#configuration div.colours div.colour {
  font-size: 12px;
}







/*
 * entry page
 */

$entry-grey: #6A6A6A;

div#entry, div#designer {

  div.white {
    background: #FFF;
    border-color: #E3E3E3;
  }

  ul.menu-options {
    > li {
      display: inline;
      font-size: 16px;
      padding-right: 20px;

      &:last-child {
        padding-right: 0px;
      }
      
      > a {
        color: $grey-off;
        text-decoration: none;
        transition: 0.5s ease all;

        &:hover {
          color: $grey;
        }

        &.btn {
          background: $red;
          color: #FFF;

          &:hover {
            color: $grey;
          }
        }
      }
    }
  }


  div#introduction {

    text-align: center;
    margin-bottom: 20px;

    h1 {
      margin-bottom: 4px;
      color: $entry-grey;
      font-size: 30px;
    }

    h2 {
      margin: 30px 0;
      line-height: 24px;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 8px;
      }
    }

    h3 {
      margin-top: -20px;
      margin-bottom: 20px;
      line-height: 20px;
      font-size: 20px;
      font-weight: bold;
      color: $entry-grey;
    }

    p {
      margin-bottom: 2px;
      font-size: 16px;
      color: $entry-grey;
    }

  }


  div#entry-points {

    > div {

      padding: 0;
      border: 2px solid $grey;
      cursor: pointer;
      transition: 1s ease all;

      &.active {
        border-color: $red;

        > div.content::before {
          position: absolute;
          content: url(/images/icons/tick-red.png);
          top: 20px;
          right: 40px;
        }
      }

      > img {
        width: 100%;
      }

      > div.content {
        transition: 1s ease all;
        position: relative;
        padding: 10px 30px 20px;
        background: #FFF;
        color: $entry-grey;

        > h3 {
          margin-bottom: 4px;
          font-weight: bold;
          line-height: 4px;
        }

        > p {
          margin-top: 20px;
          font-size: 16px;
        }

      }

    }

  }


  div#entry-footer {
    margin-top: 20px;

    button {
      position: relative;
      background: $red;
      width: 100%;
      padding: 10px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border: 0px;
      border-radius: 6px;
      text-align: center;
      font-size: 1.8rem;
      color: #FFF;

      > i.fa {
        position: absolute;
        top: 14px;
        right: 20px;
      }
    }
  }



  /*
   * summary page
   */

  div#summary {
    padding-top: 30px;

    > div {
      padding-left: 30px;
      padding-bottom: 20px;
      width: 48%;
      min-height: 640px;
      border: 1px solid #DBDBDB;

      // summary list on left
      &#summary-list {
        margin-right: 2%;

        h2 {
          color: $grey-dark;
          font-weight: bold;
        }

        p {
          &.summary-paragraph {
            color: $grey-dark;
            font-weight: bold;
          }
        }

        img {
          padding: 10px 0 20px;
          width: 100%;
        }

        > div.row {
          margin-left: 0px;
          margin-right: 0px;
          color: $grey-dark;
          font-size: 1.2rem;

          > div {
            margin: 1px 0;
            padding: 4px;
            background: $grey-light;

            &:nth-child(2) {
              width: 64%;
              margin-left: 1%;
            }
          }
        }
      }


      // registration form on right
      &.summary-container {
        margin-left: 2%;
        padding-right: 40px;

        h2 {
          color: $grey-dark;
          font-weight: bold;
        }

        h3 {
          font-size: 5rem;
          font-weight: bold;
          color: $red;
        }

        small {
          font-size: 1.1rem;
          color: $grey-dark;

        }
        
        label {
          font-weight: normal;
          color: $grey-dark;
          cursor: pointer;
        }

        div.row {
          padding-top: 20px;
        }

        button {
          position: relative;
          padding: 10px;
          background: $red;
          width: 100%;
          border: 0px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          border-radius: 6px;
          font-size: 2rem;
          color: #FFF;

          > i {
            position: absolute;
            top: 14px;
            right: 20px;
          }
          
          &[disabled="disabled"] {
            background: $grey-off;
            cursor: not-allowed;
          }
        }

      }

    }

  }

}

.right {
  float: right;
  color: #E30713;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.vent-colours {
  display: none;
}

.btn-primary {
  background-color: #E30713;
  border-color: #880000;

  &.active, &:hover, &.active:hover, &:focus, &.active:focus, &:active, &.active:active, &:visited, &.active:visited {
    background-color: #B3050E;
    border-color: #880000;
  }
}

.btn-default {
  background-color: #dcdcdc !important;
  border-color: #cfcfcf !important;
  color: #333333 !important;

  &.active, &:hover, &.active:hover, &:focus, &.active:focus, &:active, &.active:active, &:visited, &.active:visited {
    background-color: #cfcfcf !important;
    border-color: #b7b7b7 !important;
  }
}

.input-group {
  margin-bottom: 5px;

  .input-group-addon {
    //width: 60px;
  }

}

#entry {
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
  .input-group-lg {
    width: 100%;
  }
}

.btn-group {
  .btn-primary:first-of-type {
    border-radius: 0;
  }
}

.logout-btn {
  border: none;
  background-color: lightgrey;
  color: #0077ff;
  text-align: center;
  font-size: 14px;

  &:hover {
    color: #0052af;
  }
}

.logout-form {
  padding-top: 3px;
  float: right;

  .logout-btn-nav {
    display: inline-block;
    border: none;
    background-color: black;
    color: #C4C4C4;
    text-align: center;
    font-size: 16px;
    transition: 0.5s ease all;

    &:hover {
      color: #ffffff;
    }
  }
}

.modal-header {
  background-color: #E30713;
  color: #fff;

  .close {
    color: #fff;
    opacity: 0.7;

      &:hover {
        opacity: 1;
      }
  }
}

#most-popular-icon {
  position: absolute;
  top: 7px;
  right: 12px;
  width: 22px !important;
  height: 22px;
  opacity: 0.6;
  border: none !important;
}

#no-vents #most-popular-icon {
  right: 22px;
}

#voucher_discount {
  display: none;
}

#cill-options > div.colour, #drainage-options > div.colour {
  width: 100px !important;
}

.btn.pull-right {
  margin-left: 10px;
 }

button#homeowner_rrp, button#homeowner_dpr {
  width: 70% !important;
  margin: 0 15%;
  display: none;
  float: left;

}

button#homeowner_dpr {
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}

#homeowner_pricing {
  display: none;
  padding-top: 15px;
}

#terms-and-conditions {
  ul {
    list-style: none;

    li {
      text-align: justify;
    }
  }
}

.delete-door {
  color: #333;
  cursor: pointer;

  &:hover {
    color: #E30713;
  }
}


/*
 * additional styling for the jetzoom image hover window
 */
div.jetzoom-blank {

  // hide the white background overlay to main image applied by the plugin
  > div:not(.jetzoom-overlay) {
    background: none !important;
  }

  // style the zoom window
  > div.jetzoom-overlay {
    position: absolute;
    width: 30% !important;
    cursor: crosshair;
    border: 8px solid #E30713 !important;
    -webkit-border-radius: 40px !important;
    -moz-border-radius: 40px !important;
    border-radius: 40px !important;
  }
}

div#entry div#summary.admin > div {
  min-height: auto;
  margin-bottom: 20px;
}

.tab-content div.row > div {
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
}

.mobile-menu {
  display: none;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  div#designer {
    width: 100%;
  }

  div#configuration {

    // door configs
    div#configs > div.door.active > img {
      border: 1px solid $red;
    }

    // slide direction
    ul#slide-direction > li {
      margin-right: 0px;
    }

    // colour size options for small displays
    div#external-colours, div#internal-colours {
      padding-top: 12px;

      div.colour {
        > img {
          width: 70px;
        }
      }
    }

    ul#slide-direction {
      > li {
        margin-right: 20px;
        width: 40%;

        > img {
          width: 90%;
        }
      }
    }

    div.colours {

      &#external-colours {
        div.colour {
          > div.ral-colour-holder::before {
            left: 2px;
            width: 62px;
          }
        }
      }

      &#internal-colours {
        div.colour {
          > div.ral-colour-holder-int::before {
            left: 2px;
            width: 62px;
          }
        }
      }

      div.colour {

        > div.ral-colour-holder, > div.ral-colour-holder-int, > div.blind-colour-holder {
          width: 70px;
          height: 70px;
        }
      }

    }

    div.technical-options > div.colour {
      width: 90px !important;

      > img {
        width: 90%;
      }
    }

  }

  div#entry-points {
    > div {
      margin: 5%;
      width: 90%;
    }
  }

  div#door-preview.active {
    padding-bottom: 0;
  }

  div#summary {
    p.option {
      width: 50%;
      float: left;
    }
    overflow: auto;
  }

  div#configuration > div#options-size > div:nth-child(1),
  div#configuration > div#options-size > div:nth-child(2) {
    width: 100%;
    margin: 0;
  }

  div#main-container {
    height: auto;
  }

  div.footer-buttons > div {
    width: 32%;
    float: left;

    &#restart {
      padding: 0;
      margin: 0 2%;
    }
  }

  div#configuration div.configs div.door.active > div.player {
    display: none;
  }

  div#configuration div.configs div.door {
    width: 100%;
    margin-bottom: 20px;
  }

  .slimScrollDiv {
    height: 90vh !important;
    z-index: 1000;
  }

  #colour-picker, #colour-picker-int, #colour-picker-tex, #colour-picker-foil, #colour-picker-blinds {
    height: 100% !important;
    overflow: scroll !important;
    background: #fff;
    z-index: 1000;
  }

  div#configuration div#hardware-colours.colours.static > div.colour {
    width: 33%;
  }

  div#configuration div.configs div.door.active > div.panel-count {
    left: 65px;
  }

  div.box {
    overflow: auto;
  }

  #no-vents #most-popular-icon {
    right: 12px;
  }

  div.footer-buttons > div > div {
    font-size: 1.5rem;
  }

  div#entry div#summary > div {
    width: 100%;
    margin: 0 0 15px;
  }

  div#entry div#summary > div#summary-list > div.row > div:nth-child(1),
  div#entry div#summary > div#summary-list > div.row > div:nth-child(2) {
    width: 49%;
  }

  #voucher_submit {
    float: right;
    margin-top: 10px;
  }

  .mobile-menu {
    display: inline-block;
  }

  .dropdown-menu {
    margin: 0;
    background: #000;
    border-radius:0;
  }

  .pull-right>.dropdown-menu {
    right: -10px;
  }

  .dropdown.open .dropdown-menu a {
    display: block;
    color: #fff;
    background: #000;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;

    &:hover {
      background: #cc0000;
      text-decoration: none;
    }
  }


  div.footer-buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 9999;
    margin: 0;
  }

  .hide-mobile {
    display: none !important;
  }

  div#visual-container {
    width: 75%;
    margin: 0;
    height: 360px;
  }
  div#designer div#summary {
    padding-top: 10px;
    width: 25%;

    p.option {
      width: 100%;
    }
  }
  div#configuration div.configs div.door {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {

  div#designer {
       width: 100%;
     }

  div#configuration {

    // door configs
    div#configs > div.door.active > img {
      border: 1px solid $red;
    }

    // slide direction
    ul#slide-direction > li {
      margin-right: 0px;
    }

    // colour size options for small displays
    div#external-colours, div#internal-colours {
      padding-top: 12px;

      div.colour {
        > img {
          width: 70px;
        }
      }
    }

    ul#slide-direction {
      > li {
        margin-right: 20px;
        width: 40%;

        > img {
          width: 90%;
        }
      }
    }

    div.colours {

      &#external-colours {
        div.colour {
          > div.ral-colour-holder::before {
            left: 2px;
            width: 62px;
          }
        }
      }

      &#internal-colours {
        div.colour {
          > div.ral-colour-holder-int::before {
            left: 2px;
            width: 62px;
          }
        }
      }

      div.colour {

        > div.ral-colour-holder, > div.ral-colour-holder-int, > div.blind-colour-holder {
          width: 70px;
          height: 70px;
        }
      }

    }

    div.technical-options > div.colour {
      width: 90px !important;

      > img {
        width: 90%;
      }
    }

  }

  div#entry-points {
    > div {
      margin: 5%;
      width: 90%;
    }
  }

  div#visual-container {
    height: 250px;
  }

  div#door-preview.active {
    padding-bottom: 0;
  }

  div#summary {
    p.option {
      width: 50%;
      float: left;
    }
    overflow: auto;
  }

  div#configuration > div#options-size > div:nth-child(1),
  div#configuration > div#options-size > div:nth-child(2) {
    width: 100%;
    margin: 0;
  }

  div#main-container {
    height: auto;
  }

  div.footer-buttons > div {
    width: 32%;
    float: left;

    &#restart {
      padding: 0;
      margin: 0 2%;
    }
  }

  div#configuration div.configs div.door.active > div.player {
    right: -104px;
  }

  div#configuration div.configs div.door {
    width: 100%;
    margin-bottom: 20px;
  }

  .slimScrollDiv {
    height: 90vh !important;
    z-index: 1000;
  }

  #colour-picker, #colour-picker-int, #colour-picker-tex, #colour-picker-foil, #colour-picker-blinds {
    height: 100% !important;
    overflow: scroll !important;
    background: #fff;
    z-index: 1000;
  }

  div#configuration div#hardware-colours.colours.static > div.colour {
    width: 33%;
  }

  div#configuration div.configs div.door.active > div.panel-count {
    left: 65px;
  }

  div.box {
    overflow: auto;
  }

  #no-vents #most-popular-icon {
    right: 12px;
  }

  div.footer-buttons > div > div {
    font-size: 1.5rem;
  }

  div#entry div#summary > div {
    width: 100%;
    margin: 0 0 15px;
  }

  div#entry div#summary > div#summary-list > div.row > div:nth-child(1),
  div#entry div#summary > div#summary-list > div.row > div:nth-child(2) {
    width: 49%;
  }

  #voucher_submit {
    float: right;
    margin-top: 10px;
  }

  .mobile-menu {
    display: inline-block;
  }

  .dropdown-menu {
    margin: 0;
    background: #000;
    border-radius:0;
  }

  .pull-right>.dropdown-menu {
    right: -10px;
  }

  .dropdown.open .dropdown-menu a {
    display: block;
    color: #fff;
    background: #000;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;

    &:hover {
      background: #cc0000;
      text-decoration: none;
    }
  }


  div.footer-buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 9999;
    margin: 0;
  }

  .hide-mobile {
    display: none !important;
  }

}

.swal2-popup {
  font-size: 1.5rem !important;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

div.colour {
  &.icon-small {
    width: 80px !important;
    padding:4px;
    font-size: 12px;
    text-align: center;
    float: left;

    & img {
      width: 100%;
    }
  }
}

.control-label small {
  font-weight: normal;
}

.helper-text {
  color: rgb(161, 161, 161);
}

